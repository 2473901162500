import {DropdownOptionProps} from 'wix-ui-tpa/Dropdown';
import {IProductOptionSelectionItem} from '@wix/wixstores-client-core/dist/es/src/types/product';

function toOption(o: IProductOptionSelectionItem): DropdownOptionProps {
  return {
    id: o.id.toString(),
    isSelectable: !o.isDisabled,
    value: o.description,
  };
}

function isVisible(o: IProductOptionSelectionItem) {
  return o.isVisible !== false;
}

export function getDropdownOptionsFromSelections(selections: IProductOptionSelectionItem[]): DropdownOptionProps[] {
  return selections.filter(isVisible).map(toOption);
}
