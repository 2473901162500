import {queryToString} from '../../services/urlUtils';

export interface ISocialNetwork {
  type: string;
  getBasUrl(): string;
  getQueryStringObj(dataObj: ISocialNetworkData): any;
}

export interface ISocialNetworkData {
  url: string;
  title?: string;
  imageUrl?: string;
}

interface WhatsappQueryStringObject {
  text: string;
}

interface FacebookQueryStringObject {
  u: string;
  title: string;
}

interface TwitterQueryStringObject {
  url: string;
  text: string;
}

interface PinterestQueryStringObject {
  url: string;
  media: string;
  description: string;
}

export class WhatsappSocialNetwork implements ISocialNetwork {
  public type: string;
  private readonly baseUrl: string;

  constructor() {
    this.type = 'whatsapp';
    this.baseUrl = 'https://api.whatsapp.com/send';
  }

  public getBasUrl(): string {
    return this.baseUrl;
  }

  public getQueryStringObj(dataObj: ISocialNetworkData): WhatsappQueryStringObject {
    return {
      text: dataObj.url,
    };
  }
}

export class FacebookSocialNetwork implements ISocialNetwork {
  public type: string;
  private readonly baseUrl: string;

  constructor() {
    this.type = 'facebook';
    this.baseUrl = 'http://www.facebook.com/sharer.php';
  }

  public getBasUrl(): string {
    return this.baseUrl;
  }

  public getQueryStringObj(dataObj: ISocialNetworkData): FacebookQueryStringObject {
    return {
      u: dataObj.url,
      title: dataObj.title,
    };
  }
}

export class TwitterSocialNetwork implements ISocialNetwork {
  public type: string;
  private readonly baseUrl: string;

  constructor() {
    this.type = 'twitter';
    this.baseUrl = 'https://twitter.com/intent/tweet';
  }

  public getBasUrl(): string {
    return this.baseUrl;
  }

  public getQueryStringObj(dataObj: ISocialNetworkData): TwitterQueryStringObject {
    return {
      url: dataObj.url,
      text: `${dataObj.title}\n`,
    };
  }
}

export class PinterestSocialNetwork implements ISocialNetwork {
  public type: string;
  private readonly baseUrl: string;

  constructor() {
    this.type = 'pinterest';
    this.baseUrl = 'http://pinterest.com/pin/create/button/';
  }

  public getBasUrl(): string {
    return this.baseUrl;
  }

  public getQueryStringObj(dataObj: ISocialNetworkData): PinterestQueryStringObject {
    return {
      url: dataObj.url,
      media: dataObj.imageUrl,
      description: dataObj.title,
    };
  }
}

export class SocialShareService {
  private readonly vendor;

  constructor(vendor: ISocialNetwork) {
    this.vendor = vendor;
  }

  public toQueryParams = (dataObj) => {
    const queryStringObj = this.vendor.getQueryStringObj(dataObj);
    return queryToString(queryStringObj);
  };

  public getSocialNetworkUrl = (dataObj: ISocialNetworkData): string => {
    const baseUrl = this.vendor.getBasUrl();
    const queryParams = this.toQueryParams(dataObj);

    return `${baseUrl}?${queryParams}`;
  };
}
