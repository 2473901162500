import * as React from 'react';
import {withGlobalProps, ProvidedGlobalProps} from '../../../../providers/globalPropsProvider';
import s from './CloseQuickView.scss';
import {CloseWithBackground} from '../../../../icons/dist/components/CloseWithBackground';

export const enum CloseQuickViewHooks {
  CTA = 'close-quick-view',
}

export const CloseQuickView = withGlobalProps(({globals: {closeWixModal}}: ProvidedGlobalProps) => {
  return (
    <button type="button" data-hook={CloseQuickViewHooks.CTA} className={s.root} onClick={closeWixModal}>
      <CloseWithBackground />
    </button>
  );
});
